import "./src/styles/site.css"

import "@fontsource/saira-condensed"
import "@fontsource/saira-condensed/500.css"
import "@fontsource/saira-condensed/600.css"
import "@fontsource/saira-condensed/700.css"
import "@fontsource/saira-condensed/800.css"
import "@fontsource/saira-condensed/900.css"

import "@fontsource/maven-pro"
import "@fontsource/maven-pro/500.css"
import "@fontsource/maven-pro/600.css"
import "@fontsource/maven-pro/700.css"
import "@fontsource/maven-pro/800.css"
import "@fontsource/maven-pro/900.css"

// Polyfill for gatsby-background-image plugin
// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    return (
      import(`intersection-observer`)
    )
  }
}